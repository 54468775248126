//register serviceworker
if ('serviceWorker' in navigator && window.location.href.search('localhost') === -1) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('sw.js'/*, {scope: '/aerzteservicetest/'}*/);
    });
}

//fetch polyfill
import 'whatwg-fetch'

//import and config notification plugin
import AWN from "awesome-notifications"
let notifier = new AWN({
    position: 'top-right',
    labels: {
        alert: 'Fehler'
    },
    messages: {
        "async-block": "Lade"
    },
    icons: {
        enabled: false
    }
});

if(document.getElementById('codeform') !== null) {
    //prefill current year
    //document.getElementById('year').value = new Date().getFullYear();

    //code is always in uppercase
    document.getElementById('code').addEventListener('input', function () {
        this.value = this.value.toUpperCase();
    });

    //submit form
    document.getElementById('codeform').addEventListener('submit', function (e) {
        e.preventDefault();

        notifier.asyncBlock(
            fetch('scripts/download.php', {
                method: 'POST',
                body: new FormData(this)
            }),
            resp => {
                if (resp.status !== 200) {
                    notifier.alert('Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut.');
                    return;
                }
                resp.json().then(data => {
                    if (data.success === false) {
                        notifier.alert(data.message);
                    } else if (data.success === true) {
                        notifier.modal('<p>Bitte nutzen Sie diesen Link, um den Befundbericht als PDF herunterzuladen:</p><a href="' + data.link + '" target="_blank" class="button" download>Befundbericht herunterladen</a>', 'modal-download');
                    }
                });
            },
            err => {
                notifier.alert('Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es erneut.');
            }
        );
    });
}

//decode mails
//E-Mail Adresse konvertieren: http://www.rot13.com/ (inkl. mailto:)
document.querySelectorAll(".maillink").forEach(function(maillink){
    let mailtolink = maildecode(maillink.dataset.mail);
    maillink.setAttribute('href', mailtolink);

    let mailtext = mailtolink.replace('@', '[at]');
    mailtext = mailtext.replace('mailto:', '');
    maillink.text = mailtext;
});
function maildecode(a) {
    return a.replace(/[a-zA-Z]/g, function(c){
        return String.fromCharCode((c <= "Z" ? 90 : 122) >= (c = c.charCodeAt(0) + 13) ? c : c - 26);
    })
}
